
.tr-left > td > .amt{
    margin: 0;
    font-size: 20px;
    color: red;
}

.f16{
    font-size: 16px;
}

.grey {
    color: grey;
}

.m5{
    margin: 7px;
}

.b{
    font-weight: bold;
}

.f18{
    font-size: 18px;
}

.m3{
    margin: 3px;
}
.m0{
    margin: 0px;
}

.f20{
    font-size: 20px;
}
.grey {
    color: grey;
}

.border-table{
    box-shadow: 2px 4px 14px lightgray;
}

.print-icon{
    color: black;
    font-size: 30px !important;
}

.print-icon:hover{
    color: dodgerblue;
    cursor: pointer;
}

.no-bd{
    border: none !important;
   
}

.lg{
    font-size: 30px;
}

.cap{
    text-transform: uppercase;
}

.red{
    color: red;
}

.green{
    color: green;
}

.lg-amt {
    font-size: 28px !important;
}

.vlg-amt {
    font-size: 32px !important;
}

.ext{
    margin: 5px;
    font-size: 14px;
    color: grey;
}

.td-title{
    font-size: 25px;
}

.tr > td {
    border-top: 1px solid  lightgrey ;
    border-right: 1px solid lightgrey;
}

.menu-div, .menu-header {
    color: black !important;
    text-align: center !important;
    place-items: center;
    justify-content: center;
    display: flex !important;
}

.menu-div > a {
    color: black ;
}



.menu-div > a:hover {
    color: dodgerblue;
    cursor: pointer;
}

.nav-link{
    width: 100%;
    height: 100%;
}

.rl-icon{
    font-size: 50px !important;
}

.rl-icon:hover{
    color: dodgerblue;
     cursor: pointer;
}


.bal{
    font-weight: bold;
    padding: 2px 5px;
    background-color: rgb(242, 242, 242);
    color: black;
}

.date-p{
    font-weight: bold;
}

.date-td{
    color: black;
}

.tr-right > td > .amt{
    margin: 0;
    font-size: 20px;
    color: green;
}
.tr > td {
    text-align: center;
    padding:3px 20px;
}

.close-btn{
    color: red;
}

.close-btn:hover{
    cursor: pointer;
}


@media screen and (max-width: 500px) {
    .tr > td {
        padding: 5px 0px !important;
    }
    .table, .border-table{
        width: 100%;
    }

  }


