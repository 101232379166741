.form-div{
    height: 70vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form{
    display: flex;
    flex-direction: column;
    
}
.form > .header {
    font-size: 30px;
}
.form > p {
    font-size: 14px;
    margin-bottom: 20px;
}

.form > a {
    margin: 20px 0px;
    color: dodgerblue;
}

.form > a:hover{
    cursor: pointer;
}

.form > button{
    margin: auto;
    color: white;
    background: dodgerblue;
    margin-top: 40px;
    width: 150px;
    height: 40px;
    outline: none;
    border: none;
    cursor: pointer;
}

.form > input {
    height: 40px;
    
    font-size: 20px ;
    padding: 10px;
}

.phone_number{
    letter-spacing: 14px;
    width: 270px;
}

.otp{
    letter-spacing: 14px;
    width: 170px;

}