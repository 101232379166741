 body {
  margin: auto;
  font-family: Roboto !important;
}

.MuiButton-root {
  text-transform: none !important ;
}

.p0{
  padding: 0px !important;
  line-height: 1 !important;
}
.f10{
  font-size: 10px;
}

.grey {
  color: grey;
}
.m1 {
  margin: 2px;
}

.MuiDrawer-paperAnchorLeft {
  padding: 40px;
}

.main-app {
  max-width: 500px;
  margin: auto;
}

.m10 {
  margin: 10px;
}

.MuiButton-root {
  min-width: 0 !important;
  padding: 6px 10px !important;
}

a {
  text-decoration: none !important;
}

.float-header {
  position: sticky;
  box-shadow: 5px 10px 15px lightgrey;
  width: max-content;
  top: 10px;
  margin-left: 20px;
  padding: 10px;
  border-radius: 19px;
  background: white;
  z-index: 999999;

  box-shadow: 5px 10px 15px lightgray;
}

.info-addr-div > p{
  font-size: 13px;
  margin: 2px;
  padding: 0px 5px;
  text-align: center;

}

.info-addr-div {
  margin-bottom: 15px;
}

.inactive {
  filter: grayscale();
  color: grey;
}

.inactive-btn {
    display: none !important;
  }

  .info-div > p{
    text-align: center;
  }

.float-logo {
  position: fixed;
  box-shadow: 5px 10px 15px lightgrey;
  width: max-content;
  top: 10px;
  right: 10px;
  margin-left: 20px;
  z-index: 999999;
  padding: 5px;
  border-radius: 19px;
  background: white;
}

.error {
  font-size: 16px;
  color: crimson;
}

.llg{
  font-size: 20px;
}
.bb{
  font-weight: bold;
}
.ttable{
  max-width: 400px;
  margin: auto;
}
tr,td{
  text-align: right;
  padding: 10px;
}
.bbt> td{
  border-top: 1px solid black;
}
.bbt{
  border-top: 1px solid black;
}
