.container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.ccard{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  width: 100%;
  margin-top: 60px;
  border-radius: 10px;
}

.pcard{
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.row{
  display: flex;
  flex-direction: row;
}

.fgrey{
  color: grey;
}

.f18{
  font-size: 18px;
}
.f22{
  font-size: 25px;
}
.toggle-btn{
  width: 120px;
  height: 40px;
  background-color: teal;
  margin-bottom: 30px;
  margin-top: 20px;
  color: white;
  border: 0px;
  outline: none;
  font-size: 18px;
  border-radius: 10px;
}

.bol{
  font-weight: bold;
}
.col-bg{
  background-color: whitesmoke;
}

.col{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px ;

}

