.search-div {
  height: 40px !important;
  background-color: whitesmoke;
  padding: 10px 5px;
  max-width: 222px;
  border-radius: 20px;
  padding: 0px 20px;
  border: 1px solid lightgray;
  /* margin: auto; */
}

.search-div:hover{
    cursor: text;
}

.paper {
  margin: auto;
  margin: 10px auto;
  align-items: center;
  max-width: 400px;
  padding: 15px;
  text-align: center;
  color: black;
}
.modal {
  background-color: white;
  padding: 15px 5px;
  max-width: 500px;
  margin: auto;
  margin-top: 40px;
}

.input {
  width: 100%;
}
.td-p {
  font-size: 25px;
  text-align: center;
}
.m0{
    margin: 0;
}

.center{
    text-align: center;
}

.bold {
  font-weight: bolder !important;
}



.bold-row > th {
  font-size: 15px;
}

.hidden {
  display: none !important;
}

.search-div:hover {
  border: 1px solid dodgerblue;
}

.action-btn-td > svg:hover {
  color: dodgerblue;
  text-align: center;
}

.search-div > svg {
  position: relative;
  top: 8px;
  left: -3px;
}
